var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[(_vm.resolutionScreen >= 500)?_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-overlay',{attrs:{"value":_vm.loaddataprogress,"z-index":"3","color":"black","dark":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"white"}},[_c('span',{style:(_vm.colorProgress)})])],1),_c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","lg11":"","ma-5":""}},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"ma-5"},[_vm._v(" "+_vm._s(_vm.$t("setusername.setusername")))]),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"text-center",attrs:{"lg10":"","xs10":""}},[_c('v-text-field',{staticClass:"pa-1 pt-0 pb-0",attrs:{"clearable":"","outlined":"","dense":"","label":_vm.$t('default') === 'th'
                        ? 'ชื่อผู้ใช้ (Username)'
                        : 'Username',"prepend-icon":"people","rules":_vm.$t('default') === 'th'
                        ? _vm.requiredusername
                        : _vm.requiredusername_eng,"required":"","error-messages":_vm.$t('default') === 'th'
                        ? _vm.usernameError
                        : _vm.usernameError_eng,"hint":_vm.$t('default') === 'th'
                        ? 'ตัวอักษรอย่างน้อย 6 ตัว และห้ามมีอักขระพิเศษ'
                        : 'Must have at least 6 characters / Do not use special characters',"persistent-hint":""},on:{"input":function($event){_vm.fn_tolowercase(), _vm.$v.username.$touch()},"blur":function($event){return _vm.$v.username.$touch()}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"text-center",attrs:{"lg10":"","xs10":""}},[_c('v-text-field',{staticClass:"pa-1 pt-0 pb-0",attrs:{"clearable":"","outlined":"","dense":"","label":_vm.$t('default') === 'th' ? 'รหัสผ่าน' : 'Password',"prepend-icon":"vpn_key","type":_vm.showpassword ? 'text' : 'password',"append-icon":_vm.showpassword ? 'mdi-eye' : 'mdi-eye-off',"rules":_vm.$t('default') === 'th'
                        ? _vm.requiredpassword
                        : _vm.requiredpassword_eng,"error-messages":_vm.$t('default') === 'th'
                        ? _vm.passwordError
                        : _vm.passwordError_eng,"required":"","hint":_vm.$t('default') === 'th'
                        ? 'ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8'
                        : 'Must contain at least one number and one English letter / Must have at least 8 characters',"persistent-hint":""},on:{"click:append":function($event){_vm.showpassword = !_vm.showpassword},"input":function($event){return _vm.$v.password.$touch()},"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"text-center",attrs:{"lg10":"","xs10":""}},[_c('v-text-field',{staticClass:"pa-1 pt-0 pb-0",attrs:{"clearable":"","outlined":"","dense":"","label":_vm.$t('default') === 'th'
                        ? 'ยืนยันรหัสผ่าน'
                        : 'ConfirmPassword',"prepend-icon":"vpn_key","type":_vm.showpassword_ ? 'text' : 'password',"append-icon":_vm.showpassword_ ? 'mdi-eye' : 'mdi-eye-off',"rules":_vm.$t('default') === 'th'
                        ? _vm.requiredconfirmpassword
                        : _vm.requiredconfirmpassword_eng,"error-messages":_vm.$t('default') === 'th'
                        ? _vm.confirmpasswordError
                        : _vm.confirmpasswordError_eng,"required":""},on:{"click:append":function($event){_vm.showpassword_ = !_vm.showpassword_},"input":function($event){return _vm.$v.confirmpassword.$touch()},"blur":function($event){return _vm.$v.confirmpassword.$touch()}},model:{value:(_vm.confirmpassword),callback:function ($$v) {_vm.confirmpassword=$$v},expression:"confirmpassword"}})],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":"","outlined":""},on:{"click":function($event){return _vm.$emit('closedialog')}}},[_vm._v(_vm._s(_vm.$t("checkpassword.cancel")))]),_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.loadprogress,"color":_vm.color.theme},on:{"click":function($event){return _vm.fn_setting_usename()}}},[_vm._v(_vm._s(_vm.$t("checkpassword.ok")))])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }