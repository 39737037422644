<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="500"
      v-if="resolutionScreen >= 500"
    >
      <v-overlay :value="loaddataprogress" z-index="3" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white">
          <span :style="colorProgress"></span>
        </v-progress-circular>
      </v-overlay>
      <v-card>
        <v-card-text>
          <v-layout row wrap align-center justify-center>
            <v-flex xs11 lg11 ma-5>
              <v-card class="elevation-0">
                <v-card-title class="ma-5">
                  {{ $t("setusername.setusername") }}</v-card-title
                >
                <v-layout row wrap justify-center>
                  <v-flex lg10 xs10 class="text-center">
                    <v-text-field
                      clearable
                      outlined
                      dense
                      class="pa-1 pt-0 pb-0"
                      v-model="username"
                      :label="
                        $t('default') === 'th'
                          ? 'ชื่อผู้ใช้ (Username)'
                          : 'Username'
                      "
                      prepend-icon="people"
                      :rules="
                        $t('default') === 'th'
                          ? requiredusername
                          : requiredusername_eng
                      "
                      required
                      :error-messages="
                        $t('default') === 'th'
                          ? usernameError
                          : usernameError_eng
                      "
                      @input="fn_tolowercase(), $v.username.$touch()"
                      @blur="$v.username.$touch()"
                      :hint="
                        $t('default') === 'th'
                          ? 'ตัวอักษรอย่างน้อย 6 ตัว และห้ามมีอักขระพิเศษ'
                          : 'Must have at least 6 characters / Do not use special characters'
                      "
                      persistent-hint
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                  <v-flex lg10 xs10 class="text-center">
                    <v-text-field
                      clearable
                      outlined
                      dense
                      class="pa-1 pt-0 pb-0"
                      v-model="password"
                      :label="$t('default') === 'th' ? 'รหัสผ่าน' : 'Password'"
                      prepend-icon="vpn_key"
                      @click:append="showpassword = !showpassword"
                      :type="showpassword ? 'text' : 'password'"
                      :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="
                        $t('default') === 'th'
                          ? requiredpassword
                          : requiredpassword_eng
                      "
                      :error-messages="
                        $t('default') === 'th'
                          ? passwordError
                          : passwordError_eng
                      "
                      required
                      @input="$v.password.$touch()"
                      @blur="$v.password.$touch()"
                      :hint="
                        $t('default') === 'th'
                          ? 'ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8'
                          : 'Must contain at least one number and one English letter / Must have at least 8 characters'
                      "
                      persistent-hint
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                  <v-flex lg10 xs10 class="text-center">
                    <v-text-field
                      clearable
                      outlined
                      dense
                      class="pa-1 pt-0 pb-0"
                      v-model="confirmpassword"
                      :label="
                        $t('default') === 'th'
                          ? 'ยืนยันรหัสผ่าน'
                          : 'ConfirmPassword'
                      "
                      prepend-icon="vpn_key"
                      @click:append="showpassword_ = !showpassword_"
                      :type="showpassword_ ? 'text' : 'password'"
                      :append-icon="showpassword_ ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="
                        $t('default') === 'th'
                          ? requiredconfirmpassword
                          : requiredconfirmpassword_eng
                      "
                      :error-messages="
                        $t('default') === 'th'
                          ? confirmpasswordError
                          : confirmpasswordError_eng
                      "
                      required
                      @input="$v.confirmpassword.$touch()"
                      @blur="$v.confirmpassword.$touch()"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark outlined @click="$emit('closedialog')">{{
            $t("checkpassword.cancel")
          }}</v-btn>
          <v-btn
          class="white--text"
           :loading="loadprogress"
           :color="color.theme"
           @click="fn_setting_usename()"
            >{{ $t("checkpassword.ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog
      v-model="show"
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
    >
      <v-overlay :value="loaddataprogress" z-index="3" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white">
          <span :style="colorProgress"></span>
        </v-progress-circular>
      </v-overlay>
      <v-card>
        <v-form class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{
                $t("checkpassword.editpassword")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                @click="
                  $emit('close'),
                    (oldpassword = ''),
                    (password = ''),
                    (confirmpassword = '')
                "
                :color="color.theme"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <span :style="titleText">{{ $t("checkpassword.oldpassword") }}</span>
          <v-text-field
            class="mt-1 mb-n2 textFieldClass"
            :color="color.theme"
            outlined
            dense
            v-model="oldpassword"
            :type="showpasswordold ? 'text' : 'password'"
            :append-icon="showpasswordold ? 'mdi-eye' : 'mdi-eye-off'"
            height="35px"
            @click:append="showpasswordold = !showpasswordold"
            autocomplete="new-password"
            persistent-hint
          ></v-text-field>
          <span :style="titleText">{{ $t("checkpassword.password") }}</span>
          <v-text-field
            class="mt-1 mb-n2 textFieldClass"
            :color="color.theme"
            outlined
            dense
            v-model="password"
            :type="showpasswordnew ? 'text' : 'password'"
            :append-icon="showpasswordnew ? 'mdi-eye' : 'mdi-eye-off'"
            height="35px"
            @click:append="showpasswordnew = !showpasswordnew"
            autocomplete="new-password"
            persistent-hint
          ></v-text-field>
          <span :style="titleText">{{
            $t("checkpassword.confirmpassword")
          }}</span>
          <v-text-field
            class="mt-1 textFieldClass"
            :color="color.theme"
            outlined
            dense
            v-model="confirmpassword"
            :type="showconfirmpasswordnew ? 'text' : 'password'"
            :append-icon="showconfirmpasswordnew ? 'mdi-eye' : 'mdi-eye-off'"
            height="35px"
            @click:append="showconfirmpasswordnew = !showconfirmpasswordnew"
            autocomplete="new-password"
            persistent-hint
          ></v-text-field>
          <div class="text-center">
            <v-btn
              :style="btnAction"
              class="mr-6"
              height="40px"
              width="45%"
              color="red"
              outlined
              @click="
                $emit('close'),
                  (oldpassword = ''),
                  (password = ''),
                  (confirmpassword = '')
              "
              >{{ $t("checkpassword.cancel") }}</v-btn
            >
            <v-btn
              class="elevation-0"
              :style="btnAction"
              height="40px"
              width="45%"
              :color="color.theme"
              :dark="color.darkTheme"
              @click="
                editpassword(),
                  (oldpassword = ''),
                  (password = ''),
                  (confirmpassword = '')
              "
              >{{ $t("checkpassword.ok") }}</v-btn
            >
          </div>
        </v-form>
      </v-card>
    </v-dialog> -->
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";
import { log } from "console";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  mixins: [validationMixin],
  validations: {
    username: { required },
    password: { required },
    confirmpassword: { required },
  },
  data() {
    return {
      loadprogress:false,
      allowcheckpassword:"",
      allowcheckuser: "",
      username: "",
      password: "",
      confirmpassword: "",
      showpassword: false,
      showpassword_:false,
      loaddataprogress: false,
      showconfirmpasswordnew: false,
      requiredusername: [
        (value) =>
          (/^(?=.*[a-z])/.test(value) &&
            value.length >= 6 &&
            this.fn_checkusername(value)) ||
          "ตัวอักษรอย่างน้อย 6 ตัว และห้ามมีอักขระพิเศษ",
        (value) => !!value || "โปรดระบุข้อมูล.",
      ],
      requiredusername_eng: [
        (value) =>
          (/^(?=.*[a-z])/.test(value) &&
            value.length >= 6 &&
            this.fn_checkusername(value)) ||
          "Must have at least 6 characters / Do not use special characters",
        (value) => !!value || "Please fill data",
      ],
      requiredpassword: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value) &&
            this.fn_checkSpecialChar_(value)) ||
          "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8",
      ],
      requiredpassword_eng: [
        (value) => !!value || "Please fill data",
        (value) =>
          (/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value) &&
            this.fn_checkSpecialChar_(value)) ||
          "Must contain at least one number and one English letter / Must have at least 8 characters",
      ],
      requiredconfirmpassword: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) => this.checkpass(value) || "รหัสผ่านไม่ตรงกัน",
      ],
      requiredconfirmpassword_eng: [
        (value) => !!value || "Please fill data",
        (value) => this.checkpass(value) || "Passwords do not match",
      ],
    };
  },
  props: ["show"],
  computed: {
    ...mapState([
      // "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    colorProgress() {
      //return "color:" + this.color.theme + ";";
      return "color:" + "white" + ";";
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
    usernameError() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push("โปรดระบุข้อมูลชื่อผู้ใช้");
      return errors;
    },
    usernameError_eng() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push("Please fill Username");
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push("โปรดระบุรหัสผ่าน / ต้องมีอักขระอย่างน้อย 8 ตัว");
      return errors;
    },
    passwordError_eng() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push("Please fill Password / Must have at least 8 characters");
      return errors;
    },
    confirmpasswordError() {
      const errors = [];
      if (!this.$v.confirmpassword.$dirty) return errors;
      !this.$v.confirmpassword.required &&
        errors.push("โปรดยืนยันรหัสผ่าน / ต้องมีอักขระอย่างน้อย 8 ตัว");
      return errors;
    },
    confirmpasswordError_eng() {
      const errors = [];
      if (!this.$v.confirmpassword.$dirty) return errors;
      !this.$v.confirmpassword.required &&
        errors.push(
          "Please fill Confirm Password / Must have at least 8 characters"
        );
      return errors;
    },
  },
  methods: {
    async fn_setting_usename() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.password !== this.confirmpassword) {
          Toast.fire({
            icon: "error",
            title: "รหัสผ่านไม่ตรงกัน กรุณายืนยันรหัสผ่านอีกครั้ง",
          });
          this.password = "";
          this.confirmpassword = "";
        } else if (this.allowcheckuser == false) {
          Toast.fire({
            icon: "error",
            title: "รูปแบบชื่อผู้ใช้งานไม่ถูกต้อง",
          });
          // this.username = '';
        } else if (this.allowcheckpassword == false) {
          Toast.fire({
            icon: "error",
            title: "รูปแบบรหัสผ่านไม่ถูกต้อง",
          });
        } else {
          this.loadprogress = true;
          let accesstoken_ = window.atob(sessionStorage.getItem("token"));
          let user_id = CryptoJS.AES.decrypt(
            sessionStorage.getItem("user_id"),
            CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
            { mode: CryptoJS.mode.ECB }
          );
          user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8));
          let auth = await gbfGenerate.generateToken();
          let payload = {
            user_id: user_id,
            username: this.username,
            password: this.password,
            accesstoken: accesstoken_,
          };
          console.log("payload", payload);
          this.axios
          .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/update_username_and_password", payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              this.loadprogress = false;
              Toast.fire({
                icon: "success",
                title: "ตั้งค่า username password สำเร็จ"
                // title: this.$t("register.regissuccess"),
              });
              this.$emit("closedialog");
              this.$emit("reload_user");
            } else {
              Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
              this.username = '';
              this.password = '';
              this.confirmpassword = '';
            } 
          })
          .catch((error) => {
            this.createprogress = false;
            Toast.fire({
              icon: "error",
              title: error,
            });
            console.log(error);
          });
        }
      }
    },
    fn_checkusername(value) {
      console.log("value fn_checkusername", value);
      // const pattern = /^[a-z0-9]+$/;
      const pattern = /^[a-z0-9]*$/;
      //const pattern =  /^(?=.*[a-z])(?=.*[0-9]){8,}/;
      if (pattern.test(value) == true) {
        console.log("checkusername true");
        this.allowcheckuser = true;
        console.log(this.allowcheckuser);
        return true;
      } else {
        console.log("checkusername false");
        this.allowcheckuser = false;
        console.log(this.allowcheckuser);
        return false;
      }
    },
    checknameeng(value) {
      const pattern = /^[a-zA-Z\s]+$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkSpecialChar_(value) {
      let allow = true;
      let specialChar = [" ", '"', "'"];

      for (let index = 0; index < specialChar.length; index++) {
        const element = specialChar[index];
        if (value.includes(element)) {
          allow = false;
          break;
        }
      }
      this.allowcheckpassword = allow;
      console.log("allow", allow);
      return allow;
    },
    fn_tolowercase() {
      this.username = this.username.toLowerCase();
    },
    checkpass(value) {
      if (value === this.password) {
        return true;
      } else {
        return false;
      }
    },

  },
};
</script>
<style>
.v-text-field.textFieldClass .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-input.textFieldClass input {
  font-size: 14px;
}
</style>
